<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		
		<div class="section1">
			<ul class="list">
				<li class="item" v-for="(item,index) in list" :key="index">
					<div class="box1">
						<div class="title">{{item.company}}</div>
						<div class="desc">{{item.cardid}}</div>
					</div>
					<div class="box2">
						<div class="radio" @click="setDefault(index)">
							<img class="icon" :src="item.default == 1 ? require('@/assets/images/radio_checked.png') : require('@/assets/images/radio_uncheck.png')" />
							<div class="title">设为默认地址</div>
						</div>
						<div class="button-group">
							<router-link :to="'/invoice/info?id=' + item.id" class="btn btn1">修改</router-link>
							<div class="btn btn2" @click="del(index)">删除</div>
						</div>
					</div>
				</li>
			</ul>
			<div class="add-box">
				<router-link to="/invoice/info" class="btn">新增抬头</router-link>
			</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '开票资料',
				list: []
			}
		},
		computed: {
			uid() {
				this.$store.commit('saveUid',this.$route.query.uid)
				return this.$route.query.uid;
			}
		},
		activated(){
			this.getUserInfo();

		},
		deactivated() {
			this.list = [];
		},
		methods: {
			// 设为默认
			async setDefault(index) {
				var uid = this.uid;
				var list = this.list;
				var id = list[index]['id'];
				var data = {
					uid, id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/user/invoiceDefault',data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('开票资料 设为默认：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$toast.success(res.data);
					for(var i = 0; i < list.length; i++){
						if(index == i){
							list[i]['default'] = 1;
						} else {
							list[i]['default'] = 0;
						}
					}
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
				
			},
			// 删除发票资料
			async del(index) {
				var uid = this.uid;
				var list = this.list;
				var id = list[index].id;
				var data = {
					uid, id
				}
				const confirmResult = await this.$messageBox.confirm('此操作将永久删除该发票资料, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).catch(error => error);
				if(confirmResult != 'confirm'){
					return this.$toast('已取消删除');
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/invoiceDel', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('开票资料 删除开票资料：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$toast.success('删除成功!');
					list.splice(index, 1);
				} else {
					this.$toast.error(res.data);
				}
			},
			// 获取开票资料列表
			async getList() {
				var uid = this.uid;
				var data = {
					uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/invoiceList', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('开票资料列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var list = res.data;
					this.list = list;
				} else {
					this.list = [];
				}
			},
			// 获取用户信息
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return ;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getList();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		padding-bottom: 1rem;
	}
	.list{
		padding: 0.3rem 0;
	}
	.list .item{
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		margin-top: 0.3rem;
	}
	.list .item:first-child{
		margin-top: 0;
	}
	.list .item .box1{
		display: flex;
		flex-direction: column;
		padding: 0.3rem 0.3rem 0.3rem 1.28rem;
		background: url(../../../assets/images/icon62.png) no-repeat left 0.3rem center;
		background-size: 0.8rem 0.8rem;
		border-bottom: 0.02rem solid #e0e0e0;
	}
	.list .item .box1 .title{
		font-size: 0.28rem;
		color: #333333;
		line-height: 0.4rem;
	}
	.list .item .box1 .desc{
		font-size: 0.28rem;
		color: #333333;
		line-height: 0.4rem;
	}
	.list .item .box2{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.3rem;
		height: 0.74rem;
	}
	.list .item .box2 .radio{
		display: flex;
		align-items: center;
		height: 0.4rem;
	}
	.list .item .box2 .radio .icon{
		width: 0.3rem;
		height: 0.3rem;
	}
	.list .item .box2 .radio .title{
		font-size: 0.24rem;
		color: #333333;
		margin-left: 0.12rem;
	}
	.list .item .box2 .button-group{
		display: flex;
		align-items: center;
	}
	.list .item .box2 .button-group .btn{
		font-size: 0.24rem;
		color: #969897;
		padding-left: 0.45rem;
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 0.3rem 0.3rem;
		line-height: 0.4rem;
	}
	.list .item .box2 .button-group .btn1{
		background-image: url(../../../assets/images/icon65.png);
		margin-right: 0.4rem;
	}
	.list .item .box2 .button-group .btn2{
		background-image: url(../../../assets/images/icon66.png);
	}
	.add-box{
		margin-top: 0.9rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.add-box .btn{
		width: 5.8rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #c90719;
		border-radius: 0.5rem;
		font-size: 0.32rem;
		color: #ffffff;
	}
</style>